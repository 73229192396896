







































import { Component, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";

@Component({
  components: {
    Layout,
  },
})
export default class extends Vue {
  hbgsrc: string = require("@/assets/home/img/shehuizerenbg.png");

  list = [
    {
      id: 1,
      title: "环境",
      des: "持续推进低碳绿色建筑的发展，不断打造绿色办公新模式，领跑城市楼宇绿色新赛道。以绿色环保的先锋态度，以智慧和科技为两翼，积极推动环境可持续发展。",
      src: require("@/assets/social/img/huanjing.png"),
      url: "/home/social/enviroment",
    },
    {
      id: 2,
      title: "社会",
      des: "建设扶贫光伏电站，加快农村能源转型发展，激发贫困地区发展动力及发展能力，为乡村振兴注入绿色活力，创造社会公益价值。",
      src: require("@/assets/social/img/shehui.png"),
      url: "/home/social/society",
    },
    {
      id: 3,
      title: "责任",
      des: "推进低碳社会经济与构建智慧能源生态，用以科技+服务助力环境可持续发展，将社会责任一以贯之。",
      src: require("@/assets/social/img/zhili.png"),
      url: "",
    },
  ];

  navigate(path: string) {
    this.$router.push({
      path,
    });
  }
}
